<template>
  <div class="releases">
    <Header message="Releases" />
    <Store :releases="releases" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import data from "../data/data.json";

import Header from "../components/Header.vue";
import Store from "../components/Store.vue";

export default defineComponent({
  components: {
    Header,
    Store,
  },
  setup: () => {
    const releases = ref([]);

    data.artists.forEach((artist) => {
      releases.value.push(...artist.releases);
    });

    return { releases };
  },
});
</script>

<style scoped lang="scss">
.releases {
  padding-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .releases {
    padding-bottom: 30px;
  }
}
</style>
